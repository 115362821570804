table.organisations {

    thead {
        th {
            &.name {
                width: 25%;
                padding-left: 15px;
            }

            &.category {
                width: 15%;
            }

            &.schacHomes {
                width: 30%;
            }

            &.role {
                width: 10%;
                text-align: center;
            }

            &.organisation_memberships_count {
                width: 10%;
                text-align: center;
            }

            &.collaborations_count {
                width: 10%;
                text-align: center;
            }
        }
    }

    tbody {
        td {
            &.name {
                padding-left: 15px;
            }

            &.role {
                width: 20%;
                text-align: center;
            }

            &.organisation_memberships_count {
                text-align: center;
            }

            &.collaborations_count {
                text-align: center;
            }

            div.schac_home_organisations {

                div.schac_home_container {
                    position: relative;

                    .sds--tooltip-container {
                        position: absolute;
                        top: -5px;
                        right: -5px;
                    }

                }

                span.schac_home {
                    display: block;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    word-break: break-word;
                }
            }
        }
    }
}
