table.services {

    thead {
        th {
            &.name {
                width: 50%;
                padding-left: 15px;
            }

            &.connection_requests_count {
                width: 24%;
                text-align: center;
            }

            &.collaborations_count {
                width: 24%;
                text-align: center;
            }
        }
    }

    tbody {
        td {
            &.name {
                padding-left: 15px;
            }

            &.connection_requests_count {
                text-align: center;
            }

            &.organisations_count {
                text-align: center;
            }

            &.collaborations_count {
                text-align: center;
            }
        }
    }
}
