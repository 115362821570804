.mod-users {
    position: relative;

    .mod-spinner-loading {
        position: absolute;
        top: -90px;
        right: 50%;
    }

    .more-results-available {
        text-align: center;
        margin: 0 auto;
        flex-grow: 2;
    }

    table.allUsers {

        thead {
            th {
                &.name {
                    width: 20%;
                }

                &.schac_home_organisation {
                    width: 15%;
                }

                &.username {
                    width: 15%;
                }

                &.uid {
                    width: 25%;
                }

                &.collaborations {
                    width: 20%;
                }
            }
        }

        tbody {
            ul {
                list-style: initial;

                li {
                    margin-left: 15px;
                }
            }
        }
    }

}
