@import "../stylesheets/vars.scss";


.mod-collaborations-overview {
    max-width: $medium;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $tablet-max) {
        padding: 0 15px;
    }

    h2 {
        margin: 25px;
    }

    .tree-swing-container {
        margin-top: 25px;

        svg {
            border: 6px solid var(--sds--palette--main-color--300);
            padding: 38px 25px;
            border-radius: 50%;
            height: 235px;
            width: auto;
            margin: auto;
        }
    }

    .collaborations {
        display: flex;
        flex-direction: column;
        width: 640px;
        margin: 25px auto 0 auto;

        .sds--content-card {
            margin-bottom: 25px;
        }
    }

    .actions {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
        margin-bottom: 25px;
    }
}
