@import "../stylesheets/vars.scss";

div.mod-aup-organisation {
    margin-top: 20px;
    padding: 10px 0;
    border-top: 3px solid $lighter-grey;
    border-bottom: 3px solid $lighter-grey;

    div.organisation-section {
        display: flex;
        padding: 0 10px;
        align-items: center;
        width: 100%;
        min-height: 56px;
        margin-bottom: 15px;

        img {
            border-radius: 4px;
            max-width: 76px;
        }

        div.border-right {
            margin-right: 25px;
            padding-right: 25px;
            border-right: 1px solid $dark-grey;
        }

        table {
            width: 100%;

            td.logo {
                width: 85px;
            }

            td.organisation-detail {
                width: 90%;

                div.organisation {
                    display: flex;
                    flex-direction: column;
                    margin-left: 15px;

                    span.organisation-name {
                        margin-bottom: 6px;
                    }
                }

                div.policies {
                    display: flex;
                }

                div.contact {
                    display: flex;
                    align-content: center;

                    svg {
                        margin-right: 10px;
                    }
                }
            }

        }

        span.no-link {
            color: $darker-grey;
            font-style: italic;
            max-width: 220px;
            display: inline-block;
        }
    }

    p.aup-info {
        margin-bottom: 15px;
    }

    div.terms {
        margin: 25px 0 0 0;
    }


}