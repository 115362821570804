@import "../stylesheets/vars.scss";
@import "../stylesheets/forms";

.mod-user-profile {
    span.username {
        font-weight: 600;
    }

    div.user-profile {
        margin-bottom: 25px;
        width: 50%;

        @media (max-width: $compact-medium) {
            width: 100%;
        }

        .attributes {
            display: flex;
            flex-direction: column;

            span.attribute-key {
                margin: 12px 0 6px 0;
                color: black;
                font-weight: bold;
            }

        }

        div.input-field, div.select-field {
            margin-top: 15px;
        }

        .required-marker {
            color: var(--sds--color--red--400);
            font-size: 18px;
            font-weight: bold;
        }

        div.checkbox {
            margin-top: 10px;
        }

    }

    div.user-history {
        max-width: $medium;
        margin: 25px auto;
        width: 100%;

        .search-activity {
            display: flex;
            align-items: center;
            margin-top: 10px;
            width: 100%;

            @media (max-width: $compact-medium) {
                flex-direction: column;
                align-items: normal;
                padding: 0 15px;
            }

            p {

                @media (max-width: $compact-medium) {
                    margin-bottom: 15px
                }

            }

            .search {
                position: relative;
                display: flex;
                margin-left: auto;

                @media (max-width: $compact-medium) {
                    margin-left: 0;
                }

                svg.fa-search, svg.fa-magnifying-glass {
                    position: absolute;
                    font-size: 16px;
                    color: $blue;
                    top: 7px;
                    right: 9px;
                }

                input[type=text] {
                    flex-grow: 2;
                    border: 1px solid $lighter-grey;
                    padding: 0 15px 0 10px;
                    min-height: 38px;
                    font-size: 16px;
                    border-radius: $br;
                    min-width: 360px;

                    @media (max-width: $compact-medium) {
                        min-width: 0;
                    }


                    &:focus {
                        outline: none;
                        box-shadow: 1px 1px 1px $blue-hover, -1px -1px 1px $blue-hover;
                    }
                }

            }


        }
    }

}
