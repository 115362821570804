@import "../../stylesheets/vars";
@import "../../stylesheets/select_role";

.mod-entities {
    div.impersonation {
        display: flex;
    }

    .member-filter {
        display: flex;
        margin: 0 auto;
        align-items: center;
        @media (max-width: $compact-medium) {
            flex-direction: column;
        }

        .sds--checkbox-container {
            margin-left: 15px;
        }

        .member-filter-select {
            width: 290px;
            @media (max-width: $compact-medium) {
                margin: 15px 0;
            }

        }
    }

    span.member-role {
        display: inline-block;
        margin-left: 5px;
    }

    div.expiration-toggle {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 5px;
        border-radius: $br;
        width: 100%;

        .text-container {
            display: flex;
            width: 100%;
            flex-direction: column;
        }

        &:hover {
            background-color: $light-blue;
        }

        span.status {
            font-weight: bold;

            &.expires {
                color: var(--sds--palette--main-color--200);
            }

            &.expired {
                color: var(--sds--color--red--400);
            }
        }

    }

    table.coAdmins, table.members {

        thead {
            th {
                &.check {
                    width: 15px;
                }

                &.name {
                    width: 25%;

                    &.no-admin-columns {
                        width: 40%;
                    }
                }

                &.user__schac_home_organisation {
                    width: 15%;

                    &.no-admin-columns {
                        width: 25%;
                    }
                }

                &.role {
                    width: 20%;
                    padding-left: calc(var(--sds--space--2) + 5px);
                }

                &.expiry_date {
                    width: 20%;
                    padding-left: calc(var(--sds--space--2) + 5px);
                }

                &.groups {
                    width: 15%;
                }

                &.impersonate {
                    width: 87px;
                    text-align: right;
                }
            }
        }

        tbody {

            ul {
                list-style: initial;

                li {
                    margin-left: 15px;
                }
            }

            td {
                &.status {
                    text-align: center;
                }

                &.member {
                    text-align: left;
                }

                &.impersonate {
                    text-align: right;
                    padding: 0;
                }

                &.expiry_date {
                    text-align: left;

                    div.date-field-container {
                        display: flex;
                        max-width: 80%;
                        align-items: center;

                        .chevron-container {
                            display: flex;
                            margin-left: auto;
                            margin-right: 15px;

                            &:hover {
                                background-color: $light-blue;
                            }

                        }

                        svg.chevron-up, svg.chevron-down {
                            height: 17px;
                            width: auto;
                            fill: $blue;
                            cursor: pointer;

                            path {
                                stroke-width: 2px;
                                stroke: $blue;
                            }
                        }


                        div.date-field {
                            margin: 0;

                            label.date-picker-container {
                                width: 160px;
                            }

                            .react-datepicker-wrapper {
                                width: 160px;
                            }

                            .react-datepicker__input-container {
                                input[type="text"] {
                                    padding: 10px;
                                    font-size: 14px;
                                    height: 38px;

                                }

                                .react-datepicker__close-icon {
                                    top: -22px;
                                    right: -10px;
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    .select-container {
        z-index: 4;
    }

}


