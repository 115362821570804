@import "../stylesheets/vars.scss";

.mod-login-container {
    @media (max-width: $tablet-max) {
        padding: 0 15px;
    }
    background-color: white;

    &.bottom {
        background-color: $background;
    }

    .mod-login {
        @media (max-width: $compact-medium) {
            flex-direction: column;
            width: 100%;
        }
        margin: 60px auto 0;
        max-width: 768px;
        display: flex;
        padding-bottom: 35px;

        &.reversed {
            flex-direction: row-reverse;
            @media (max-width: $compact-medium) {
                flex-direction: column;
            }
        }

        &.bottom {
            flex-direction: column;
            margin: 30px auto 0;
        }

        &.info {
            background-color: white;
            border-radius: 16px;
            padding: 25px 40px;
            margin: 30px auto 0;
            min-height: 244px;
        }

        .header-left {
            margin-right: 50px;
            width: 50%;

            button.blue {
                width: 280px;
                padding: 15px 60px;
                font-size: 22px;
            }

            @media (max-width: $compact-medium) {
                width: auto;
                margin: 25px auto;
            }

            &.info {
                width: 50%;
                @media (max-width: $compact-medium) {
                    width: auto;
                    margin: 25px auto;
                }

            }
        }

        .header-right {
            width: 50%;
            @media (max-width: $compact-medium) {
                width: auto;
                margin: 25px auto;
            }

            img {
                width: 310px;
                height: auto;

                @media (max-width: $compact-medium) {
                    width: 180px;
                }

            }

            &.info {
                display: flex;
                width: 55%;
                @media (max-width: $compact-medium) {
                    width: auto;
                    margin: 25px auto;
                }

                img {
                    margin: 0 0 0 20px;
                    width: 220px;
                    height: auto;

                    &.reversed {
                        margin: 0 40px 0 0;
                    }

                    @media (max-width: $compact-medium) {
                        width: 180px;
                    }

                }
            }
        }
    }

    .info-title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .admin-function-container {
            margin-left: auto;

            span.admin-function {
                align-items: center;
                display: flex;
                background-color: #b2e4fe;
                border-radius: 40px;
                padding: 2px 8px;
                font-size: 13px;
            }

        }
    }

    .landing-footer {
        background-color: white;
        border-radius: 16px;
        padding: 15px 25px;
        margin: 30px auto 0;
        max-width: 768px;
        width: 100%;

    }


}
