@import "../stylesheets/vars.scss";

.mod-missing-service-aup {
    max-width: $compact-medium;
    margin: 25px auto 40px auto;
    width: 100%;

    @media (max-width: 680px) {
        padding: 0 15px;
    }

    h1 {
        margin-bottom: 25px;
    }

    h3 {
        margin: 20px 0 15px 0;
    }

    p.info {
        margin: 20px 0;
    }

    div.actions {
        margin: 25px 0;
    }

}