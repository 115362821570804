@import "./vars.scss";

body {

    &:after {
        position: fixed;
        width: 109px;
        height: 28px;
        top: 15px;
        right: -25px;
        text-align: center;
        font-size: 16px;
        font-family: sans-serif;
        text-transform: uppercase;
        font-weight: bold;
        color: white;
        line-height: 30px;
        transform: rotate(45deg);
    }

    @media (max-width: $compact-medium) {
        &:after {
            content: "" !important;
            background: transparent !important;
            z-index: -1;
        }
    }

    &.ReactModal__Body--open {
        overflow: hidden;
    }

    .ReactModal__Content {
        max-height: 100vh;
        overflow-y: auto;
    }

}

::-webkit-input-placeholder {
    color: grey;
    font-family: "Source Sans Pro", sans-serif;
}

::-moz-placeholder {
    color: grey;
    opacity: 1;
    font-family: "Source Sans Pro", sans-serif;
}

:-ms-input-placeholder {
    color: grey;
    font-family: "Source Sans Pro", sans-serif;
}

textarea {
    font-family: "Source Sans Pro", sans-serif !important;
}

.title, .title-header {
    font-family: Nunito, sans-serif;
}

a {
    font-weight: 600;
    color: var(--sds--palette--support-color--400);

    &:visited {
        color: var(--sds--palette--support-color--500);
    }

    &.neutral-appearance {
        font-weight: normal !important;
        text-decoration: none !important;
        color: black !important;
    }
}
