@import "../stylesheets/vars.scss";


.tab {
    min-width: 110px;
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 0.25rem solid transparent;
    font-weight: 600;
    border-top: 0.1875rem solid transparent;
    background-color: var(--sds--color--gray--300);
    padding: 0 8px;
    cursor: pointer;

    &.busy {
        min-width: 85px;
    }

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }

    @media (max-width: $compact-medium) {
        margin: 0;
    }

    svg {
        width: 20px;
        font-size: 18px;
        height: auto;
        margin-right: 10px;
    }

    &:not(.read-only):hover {
        background-color: var(--sds--palette--support-color--100);

        span.tab-label, svg {
            color: black;
        }

        svg {
            fill: black;
        }
    }

    &:not(.active) {
        span.tab-label, svg {
            color: var(--sds--color--black);
        }

        svg {
            color: var(--sds--color--black);
        }


        &.read-only {
            cursor: not-allowed;

            span.tab-label, svg {
                color: $medium-grey;
            }

            svg {
                fill: $medium-grey;
            }
        }
    }

    &.active {
        color: var(--sds--color--black);
        border-top: 0.1875rem solid var(--sds--palette--support-color--400);
        background-color: var(--sds--color--white);

        span.tab-label, svg {
            color: var(--sds--color--black);
        }

        svg {
            fill: var(--sds--color--black);

        }
    }

    button {
        padding: var(--sds--space--1-and-a-half) var(--sds--space--2);
        min-height: 3rem;
        font-weight: 600;
        display: flex;
        align-items: center;
        margin: 0 auto;
        cursor: pointer;
    }

    .sds--badge--number {
        margin-left: 4px;
    }

    span.notifier {
        position: absolute;
        right: -8px;
        top: 0;

        svg.alert-circle {
            width: 16px;
            height: auto;
        }

        @media (max-width: $compact-medium) {
            top: 8px;
            right: 4px;
        }

    }

    &.collaborations {
        svg {
            width: 28px;
        }
    }

    &.collaboration_requests {
        svg {
            width: 22px;
        }

    }

    &.serviceConnectionRequests {
        svg {
            width: 32px;
            height: auto;
        }

    }

    &.apikeys {
        svg {
            width: 28px;
        }
    }

    span.tab-label {
        font-weight: 600;
    }
}


