@import "../../stylesheets/vars";

table.member_collaboration_requests, table.system_collaboration_requests, table.deleted_collaboration_requests {

    thead {
        th {
            &.name {
                width: 22%;
                padding-left: 15px;
            }

            &.user__name {
                width: 22%;
            }

            &.organisation__name {
                width: 22%;
            }

            &.created_at {
                width: 17%;
            }

            &.status {
                width: 17%;
                text-align: center;
            }
        }
    }

    tbody {
        td {
            &.name {
                padding-left: 15px;
            }

            &.status {
                text-align: center;
            }

            &.open {
                text-align: right;
            }
        }
    }
}

.mod-entities {

    .collaboration-request-filter {
        display: flex;
        @media (max-width: $compact-medium) {
            flex-direction: column;
        }

        .collaboration-request-filter-select {
            width: 290px;
            @media (max-width: $compact-medium) {
                margin: 15px 0;
            }

        }
    }

}

