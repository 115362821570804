@import "../../stylesheets/vars";

section.copy-to-clipboard {
    border-radius: 4px;
    border: 1px solid $medium-blue;
    padding: 0 6px;
    font-size: 16px;
    color: $medium-blue;
    display: flex;
    background-color: white;
    cursor: pointer;

    &.transparent {
        background-color: transparent;
    }

    &:hover {
        background-color: #ddf2fd;
    }

    &.right {
        margin-left: 10px;
    }

    svg {
        margin: auto;
        width: 32px;
        height: auto;

        &:hover {
            color: $hover-blue;
        }
    }

    &.copied svg, svg.copied {
        animation: pulse .6s;
        animation-iteration-count: 1;

        @keyframes pulse {
            0% {
                box-shadow: 0 0 0 0 white;
                border-radius: 50%;
                padding: 2px;
            }
            35% {
                box-shadow: 0 0 1px 1px lighten($blue, 15%);
                border-radius: 50%;
                padding: 2px;
            }
            100% {
                box-shadow: 0 0 0 0 white;
                border-radius: 50%;
                padding: 2px;
            }
        }
    }

}


