@import "../stylesheets/vars.scss";

div.organisation-units {
    display: flex;
    position: relative;
    flex-direction: column;
    margin-top: 27px;

    a.add-unit {
        margin-top: 15px;
    }
}