@import "../../stylesheets/vars";

.mod-welcome-container {

    max-width: 768px;
    margin: 0 auto 30px auto;
    width: 100%;

    @media (max-width: $compact-medium) {
        padding: 0 15px;
    }

    .mod-welcome {
        h1 {
            margin: 25px 0;
        }

        h2 {
            margin-top: 25px;
        }

    }

    .known-organisation {
        display: flex;
        gap: 60px;
        margin: 25px 0 50px 0;
        align-items: center;
    }

    .unknown-organisation {
        margin: 25px 0 50px 0;
    }

}
