@import "../stylesheets/vars.scss";

.top-container {

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    background-color: $background;

    .mod-login-container {
        @media (max-width: $tablet-max) {
            padding: 0 15px;
        }
        background-color: white;

        .header-title {
            margin-bottom: 60px;
        }

        &.bottom {
            background-color: $background;
        }

        .mod-login {
            @media (max-width: $compact-medium) {
                flex-direction: column;
            }
            margin: 60px auto 0;
            max-width: 768px;
            width: 100%;
            display: flex;
            padding-bottom: 35px;

            &.reversed {
                flex-direction: row-reverse;
                @media (max-width: $compact-medium) {
                    flex-direction: column;
                }
            }

            &.bottom {
                flex-direction: column;
                margin: 30px auto 0;
            }

            &.info {
                background-color: white;
                border-radius: 16px;
                padding: 25px 40px;
                margin: 30px auto 0;
                min-height: 244px;

                @media (max-width: $compact-medium) {
                    padding: 15px 20px;
                }
            }

            .header-left {
                margin-right: 40px;
                width: 50%;

                button.sds--btn--primary {
                    width: 280px;
                    padding: 15px 60px;
                    font-size: 22px;
                }

                @media (max-width: $compact-medium) {
                    width: auto;
                    margin: 25px auto;
                }

                &.info {
                    width: 50%;
                    @media (max-width: $compact-medium) {
                        width: auto;
                        margin: 25px auto;
                    }

                }
            }

            .header-right {
                width: 48%;
                @media (max-width: $compact-medium) {
                    width: auto;
                    margin: 25px auto;
                }

                @keyframes logo-spin {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }

                img {
                    width: 310px;
                    height: auto;

                    @media (max-width: $compact-medium) {
                        width: 180px;
                    }

                    &.spin {
                        animation: logo-spin 1 0.5s linear;
                    }
                }

                &.info {
                    display: flex;
                    width: 55%;
                    @media (max-width: $compact-medium) {
                        width: auto;
                        margin: 25px auto;
                    }

                    img {
                        margin: 0 0 0 20px;
                        width: 220px;
                        height: auto;

                        &.reversed {
                            margin: 0 40px 0 0;
                        }

                        @media (max-width: $compact-medium) {
                            width: 180px;
                        }

                    }
                }
            }
        }

        p.sup {
            margin-top: 20px;
            font-size: 18px;
            line-height: 22px;
        }

    }
}
