@import "../stylesheets/vars.scss";

.date-field {
    display: flex;
    position: relative;
    flex-direction: column;

    label.date-field-label {
        display: inline-block;
        margin: 12px 0 6px 0;
        min-width: 210px;
        color: black;
        position: relative;
        font-weight: 600;
    }

    span.tool-tip-section {
        font-size: 14px;
        color: $lighter-grey;
        cursor: pointer;
        padding: 0 4px;

        &:hover {
            color: darken($light-blue, 20%);
        }
    }

    label.date-picker-container {
        display: flex;
        width: 240px;
        position: relative;
        border-radius: $br;

        &:focus {
            outline: none;
            box-shadow: 3px 3px 3px $blue-hover, -3px -3px 1px $blue-hover;
        }

        svg.fa-calendar-alt, svg.fa-calendar-days {
            position: absolute;
            right: 12px;
            top: 12px;
            color: $blue;
            cursor: pointer;
            font-size: 20px;
        }
    }

    .react-datepicker-wrapper {
        width: 240px;
    }

    .react-datepicker__input-container {
        width: 100%;

        input[type="text"] {
            display: block;
            width: 100%;
            border-radius: $br;
            border: 1px solid $dark-grey;
            line-height: 33px;
            padding: 16px;
            font-size: 14px;
            height: 48px;

            &:focus {
                outline: none;
                box-shadow: 3px 3px 3px $blue-hover, -3px -3px 1px $blue-hover;
            }

            &[disabled] {
                cursor: not-allowed;
                background-color: $background;
            }
        }

        .react-datepicker__close-icon {
            top: -22px;
            right: -10px;
        }
    }
}