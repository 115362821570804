@import "../stylesheets/vars.scss";

.mod-aup {
    max-width: 620px;
    margin: 25px auto 40px auto;
    width: 100%;

    @media (max-width: 680px) {
        padding: 0 15px;
    }

    h1 {
        margin-bottom: 20px;
    }

    div.terms {
        margin: 40px 0;
    }

}