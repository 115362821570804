@import "../../stylesheets/vars";

table.my_requests {

    thead {
        th {
            &.requestType {
                width: 20%;
                padding-left: 15px;
            }

            &.name {
                width: 20%;
            }

            &.organisationName {
                width: 30%;
            }

            &.created_at {
                width: 15%;
            }

            &.status {
                width: 15%;
                text-align: center;
            }
        }
    }

    span.schac_home {
        display: block;
    }

    tbody {
        td {
            &.name {
                padding-left: 15px;
            }

            &.status {
                text-align: center;
            }

            &.open {
                text-align: right;
            }
        }
    }
}

.mod-entities {

    .requests-filter {
        display: flex;
        @media (max-width: $compact-medium) {
            flex-direction: column;
        }

        .requests-filter-select {
            width: 290px;
            @media (max-width: $compact-medium) {
                margin: 15px 0;
            }

        }
    }

}

