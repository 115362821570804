@import "../stylesheets/vars.scss";

section.dialog-content {
    .ldap-password {
        margin: 5px 25px;
    }

}

.mod-service-container {
    span.abbreviation {
        font-weight: 600;
    }
}
