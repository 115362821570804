@import "../../stylesheets/vars";
@import "../../stylesheets/forms";

.organisation-services {
    display: flex;
    flex-direction: column;

    .toggle-switch-container {
        display: flex;
        .toggle-switch-inner-container {
            margin-left: auto;
            display: flex;
        }
        .sds--tooltip-container {
            margin-right: 20px;
        }
    }
}

table.organisationServicesUsed {

    thead {
        th {
            &.name {
                width: 50%;
                padding-left: 15px;
            }

            &.disabled {
                width: 50%;
                text-align: right;
            }
        }
    }

    tbody {
        td {
            &.name {
                padding-left: 15px;
            }

            &.allowed {
                text-align: right;
            }
        }
    }
}
