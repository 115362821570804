@import "../stylesheets/vars.scss";

.mod-system-container {
    width: 100%;

    .mod-system {
        width: 100%;
        margin: 0 auto;
        max-width: $medium;
        padding: 0;

        @media (max-width: $compact-medium) {
            padding: 0 15px;
        }


        .info-block-container {
            margin: 15px auto 15px auto;
            display: flex;
            align-items: stretch;
            flex-direction: column;

            p {
                margin: 5px 0 20px 0;

                &.title {
                    font-weight: bolder;
                }
            }

            @media (max-width: $tablet-max) {
                flex-direction: column;
            }

            .info-block {
                border-bottom: solid 4px $lightest-grey;
                padding: 15px 0;
                width: 100%;

                display: flex;
                flex-direction: row;
                align-items: center;

            }

            .actions {
                margin-left: auto;

                button:not(:first-child) {
                    margin-left: 25px;
                }

            }
        }

        p.result {
            margin: 10px 0;
            color: $dark-green;
        }

        .results {
            margin-top: 15px;

            p.sub-title {
                font-size: 20px;
                margin-bottom: 15px;

            }

            ul {
                list-style: initial;

                li {
                    margin: 10px 0 5px 20px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }

            span {
                display: inline-block;
                margin: 10px 0;

                &.sds--checkbox--visual {
                    display: flex;
                    margin: 0;
                }
            }

            table {
                width: 100%;
                margin-top: 25px;

                thead {
                    tr {
                        border-bottom: 3px solid $lighter-grey;
                    }

                    th {
                        padding: 10px 0;
                        color: $primary-orange;
                        text-align: left;
                        width: 50%;

                        &.invitation-reminders {
                            width: 33%;
                        }
                        &.invitation-expirations {
                            width: 25%;
                        }
                    }
                }

                tbody {
                    tr {
                        color: black;
                        border-bottom: 1px solid $lighter-grey;

                        &:nth-child(odd) {
                            background-color: $lightest-grey;
                        }

                        td {
                            padding: 10px 0;

                            &.action {
                                vertical-align: top;
                            }
                        }
                    }
                }
            }

            table.table-logins {

                th {
                    &.type {
                        width: 50%;
                    }

                    &.total {
                        width: 15%;
                        text-align: center;
                    }

                    &.succeeded {
                        width: 15%;
                        text-align: center;
                    }

                    &.failed {
                        width: 15%;
                        text-align: center;
                    }

                }

                tbody tr td {
                    &.count, &.succeeded, &.failed {
                        text-align: center;
                    }

                }

            }
        }

        .search-activity {
            display: flex;
            align-items: center;
            margin-top: 10px;
            justify-content: space-between;

            @media (max-width: $compact-medium) {
                flex-direction: column;
                align-items: normal;
                padding: 0 15px;
            }

            p {
                font-weight: bold;
                @media (max-width: $compact-medium) {
                    margin-bottom: 15px
                }

            }

            button.sds--delete {
                margin: 0 25px 0 auto;

                @media (max-width: $compact-medium) {
                    margin: 0 0 15px 0;
                }
            }

            div.input-select-inner {
                margin-left: 25px;
                min-width: 100px;
                @media (max-width: $compact-medium) {
                    margin-top: 15px;
                    margin-left: 0;
                }
            }
        }

        .delete-all {
            display: flex;
            margin: 25px;

        }


        .table-selection {
            display: flex;

            .select-field {
                width: 100%;
                margin-top: 0;
            }

            div.action-container {
                display: flex;

                button {
                    margin: auto 0 0 15px;
                }
            }

        }

        .toggle-json {
            display: flex;
            margin: 15px 0;

            .copy-to-clipboard {
                margin-left: 25px;
            }
        }

        .search {
            position: relative;
            display: flex;

            &.no-clear-logs {
                margin-left: auto;
            }

            @media (max-width: $compact-medium) {
                margin-left: 0;
            }

            svg.fa-search, svg.fa-magnifying-glass {
                position: absolute;
                font-size: 16px;
                color: $blue;
                top: 7px;
                right: 9px;
            }

            &.server-side {
                height: 48px;
                margin-top: auto;
                margin-left: 15px;

                svg.fa-search, svg.fa-magnifying-glass {
                    top: 14px;
                }
            }

            input[type=text] {
                flex-grow: 2;
                border: 1px solid $lighter-grey;
                padding: 0 15px 0 10px;
                min-height: 38px;
                font-size: 16px;
                border-radius: $br;
                min-width: 560px;

                @media (max-width: $compact-medium) {
                    min-width: 0;
                }


                &:focus {
                    outline: none;
                    box-shadow: 1px 1px 1px $blue-hover, -1px -1px 1px $blue-hover;
                }
            }

        }

        table.suspended-users {

            margin-bottom: 25px;

            thead {
                th {
                    &.name {
                        width: 25%;
                    }

                    &.email {
                        width: 25%;
                    }

                    &.lastLogin {
                        width: 25%;
                    }

                    &.actions {
                        width: 25%;
                    }
                }
            }

            tbody {
                td {
                    vertical-align: middle;
                }
            }
        }

        .invitation_reminders, .invitation_expirations  {
            display: flex;
            flex-direction: column;
        }

        table.open-requests {
            th {
                padding-left: 5px !important;

                &.recipient {
                    width: 15%;
                }

                &.service_requests {
                    width: 20%;
                }

                &.service_connection_requests {
                    width: 20%;
                }

                &.join_requests {
                    width: 20%;
                }

                &.collaboration_requests {
                    width: 20%;
                }
            }

            td {
                padding-left: 5px !important;
            }

            .open-requests {
                display: flex;
                flex-direction: column;

                &:not(:last-child) {
                    padding-bottom: 4px;
                    border-bottom: 1px solid $lighter-grey;
                }

            }
        }

    }
}
