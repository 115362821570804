@import "../stylesheets/vars.scss";

.feedback-content {
    display: flex;
    flex-direction: column;
    color: black;

    .sds--toaster {
        max-width: initial;
        margin: 25px 0;
    }

    section.help {
        margin-top: 15px;
    }

    section.disclaimer {
        font-size: 13px;
        margin-top: 25px;
    }
}
