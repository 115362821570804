div.org-attributes-container {
    display: flex;

    @media (max-width: $compact-medium) {
        flex-direction: column;
    }


    div.org-attributes {
        &:not(:last-child) {
            margin-right: 60px;

            @media (max-width: $tablet-max) {
                margin-right: 5px;
            }
        }

        span {
            display: block;

            &:first-child {
                font-weight: bold;
            }

            &:last-child {
                max-width: 300px;
                font-weight: normal;
                word-break: break-word;

                &.no-break {
                    max-width: 400px;
                }
            }

            &.warning {
                font-weight: bold;
                color: var(--sds--color--red--400);
            }

            &.multiple-attributes {
                display: flex;
                flex-direction: column;
            }

            a {
                max-width: 240px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                display: inline-block;
                font-weight: 600;

                @media (max-width: $compact-medium) {
                    max-width: 200px;
                }

            }
        }
    }

}

div.org-attributes-container-grid {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr 1fr;

    row-gap: 1em;
    column-gap: 10px;

    @media (max-width: $compact-medium) {
        flex-direction: column;
    }
}

div.org-attributes-container-flex {
    width: 100%;
    display: flex;
    flex-direction: column;

    div.org-attributes {
        margin-bottom: 3px;
    }
}

div.org-attributes-container-grid, div.org-attributes-container-flex {
    div.org-attributes {

        span {
            display: block;
            width: 100%;

            &:first-child {
                font-weight: bold;
            }

            &:last-child {
                font-weight: normal;
                word-break: break-word;

                &.no-break {
                    max-width: 400px;
                }

                &.tooltip-wrapper-inner {
                    word-break: break-word;
                }
            }

            &.warning {
                font-weight: bold;
                color: var(--sds--color--red--400);
            }

            &.multiple-attributes {
                display: flex;
                flex-direction: column;
            }


            a {
                display: inline-block;
                word-break: break-all;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 480px;
                font-weight: 600;

                @media (max-width: $medium) {
                    max-width: 400px;
                }

                @media (max-width: $compact-medium) {
                    max-width: 275px;
                }

            }
        }
    }

}
