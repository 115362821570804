@import "../stylesheets/vars.scss";
@import "../stylesheets/forms";

.mod-new-service-invitation {
    max-width: $medium;
    width: 100%;
    margin: 0 auto $medium-padding auto;

    @media (max-width: $tablet-max) {
        padding: 0 15px;
    }

    h2 {
        margin: 20px 0 15px 0;
    }

    .new-service-invitation {
        margin-bottom: 25px;

        .info-msg {
            color: $dark-grey;
            display: block;
            font-size: 14px;
        }

        td.invitation-link {
            padding: 12px;
            text-align: center;
        }

    }

    .invitation-form {
        width: 80%;

        @media (max-width: $tablet-max) {
            width: 100%;
        }

    }

}
