@mixin mde-preview {

    .mde-preview-content {
        p {
            margin: 0!important;
        }

        ul, ol {
            list-style: initial;
            padding: 15px 5px 15px 20px;

            li {
                padding: 2px 0;
            }
        }

    }

}
