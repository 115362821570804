@import "../stylesheets/vars.scss";

.mod-scim-container {
    .service-entities {
        display: flex;
        flex-direction: column;
        border-bottom: solid 4px $lightest-grey;
        padding: 15px 0;
        width: 100%;

    }

    div.sweep-results-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        div.sweep-results {
            display: flex;
            margin: 20px 0;
            align-items: center;

            button {
                margin-left: auto;
            }
        }
    }

    table.scimServices {

        thead {
            th {
                &.name {
                    width: 15%;
                    padding-left: 15px;
                }

                &.id {
                    width: 10%;
                }

                &.scim_url {
                    width: 30%;
                }

                &.scim_enabled, &.sweep_scim_enabled  {
                    text-align: center;
                    width: 15%;
                }

                &.sweep {
                    width: 15%;
                }
            }
        }

        tbody {
            td {
                &.name {
                    padding-left: 15px;
                }

                &.scim_enabled, &.sweep_scim_enabled {
                    text-align: center;
                }

                &.sweep {
                    text-align: right;
                }
            }
        }
    }

}