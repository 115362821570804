@import "../stylesheets/vars.scss";

.sds--modal--container.join-request-dialog {
    width: 75vw;
    max-width: 665px;
    max-height: 85vh;
    overflow: initial;
}


.join-request-form {
    margin-bottom: 15px;
    padding-bottom: 25px;
    border-bottom: 1px solid var(--sds--color--gray--200);

    .checkbox {
        margin-bottom: 25px;
    }

    .sds--radio-options {
        margin: 0;
    }
}
