@import "../../stylesheets/vars.scss";

.mod-service-container {

    .options-container {
        max-width: 1280px;
        margin: 32px auto 0 auto;

        &.no-entities {
            margin-bottom: 80px;
        }

        @media (max-width: $tablet-max) {
            padding: 0 15px;
        }

        .radio-button-container {
            margin: 32px 0 0 0;
            display: flex;
            flex-direction: column;
            row-gap: 15px;
        }
    }

    .sds--block-switch-container {
        margin: 25px 0 30px 24px;
        max-width: 910px;
        display: flex;

        @media (max-width: 1080px) {
            flex-direction: column;
        }

        .sds--block-switch {
            width: 290px;
            height: 100%;

            span.sds--text--body--small {
                font-weight: normal;
            }


        }
    }

    table.serviceOrganisations {

        thead {

            th {
                @media (max-width: $compact-medium) {
                    &.role {
                        display: none;
                    }
                }

                &.permissions {
                    width: 35%;
                }

                &.name {
                    width: 20%;
                    padding-left: 15px;
                }

                &.short_name {
                    width: 10%;
                }

                &.role {
                    width: 15%;
                    padding-left: 15px;
                }

                &.category {
                    width: 10%;
                }
            }
        }

        tbody {
            td {
                @media (max-width: $compact-medium) {
                    &.role {
                        display: none;
                    }
                }

                &.name {
                    padding-left: 15px;
                }

                &.toggle {
                    .switch-container {
                        display: flex;
                    }

                    .sds--tooltip-parent, .sds--tooltip-container {
                        margin-left: auto;
                    }

                }
            }
        }

    }

}

div.allowed-organisations-confirmation {
    text-align: left;
    padding: 0 10px;

    ul {
        margin-top: 20px;
        list-style: initial;

        li {
            margin-left: 40px;

            span {
                color: $darker-grey;
            }
        }
    }
}

.mod-entities {
    span.access-allowed-for-all {
        margin: 0 auto;
        text-align: center;
        border-radius: 12px;
        padding: 4px 15px;
        font-weight: bold;
        background-color: $yellow-light;
        color: black;

    }
}
