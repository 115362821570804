@import "../stylesheets/vars.scss";
@import "../stylesheets/forms";

div.confirmation-warning-container {
    margin-bottom: 15px;
    text-align: left;

    div.confirmation-warning {
        display: flex;
        border-radius: 4px;
        padding: 15px 0;
        margin-bottom: 15px;
        color: $red;
    }

    .input-field {
        margin-top: 0;

        label {
            text-align: left;
        }

        input[type="text"] {
            width: 100%;
        }
    }

}

div.user-profile-tab-container {
    max-width: $medium;
    margin: 0 auto;
    width: 100%;

    @media (max-width: $tablet-max) {
        padding: 0 15px;
    }

    div.user-profile-tab {
        margin: 25px 0;

        @media (max-width: $compact-medium) {
            width: 100%;
        }

        h1 {
            margin-bottom: 10px;
        }


        .sds--table {
            margin: 20px 0;

            table {

                th {
                    border-bottom: none;
                }

                td {
                    &.attribute-value {
                        font-weight: 600;
                        word-break: break-all;
                        width: 50%;
                    }

                    &.attribute-key {
                        width: 25%;
                    }

                    &.actions {
                        width: 25px;
                        text-align: right;

                        .icon-container {
                            display: flex;

                            svg {
                                margin-left: auto;
                            }
                        }
                    }

                }
            }
        }

        .profile-actions {
            display: flex;
            margin-top: 20px;

            a {
                margin-left: auto;
            }
        }

        .attributes {
            display: flex;
            flex-direction: column;

            span.attribute-key {
                margin: 12px 0 6px 0;
                color: black;
                font-weight: bold;
            }

            span.attribute-value {
                margin-bottom: 6px;

                &.none {
                    font-style: italic;
                }
            }

        }

        div.input-field, div.select-field {
            margin-top: 15px;
        }

        .required-marker {
            color: var(--sds--color--red--400);
            font-size: 18px;
            font-weight: bold;
        }

        .network-explanation {
            max-width: 580px;
            display: inline-block;
        }

        .ssh-keys-container {
            label.title {
                display: flex;
                align-items: center;
                margin: 12px 0 6px 0;
                min-width: 210px;
                color: black;
                position: relative;
                font-weight: bold;
            }

            span.tool-tip-section {
                font-size: 14px;
                color: $lighter-grey;
                cursor: pointer;
                padding: 0 4px;

                &:hover {
                    color: darken($light-blue, 20%);
                }
            }

            span.add-ssh-key {
                color: $blue;
                cursor: pointer;
                padding: 0 4px;
                font-size: 22px;
                display: inline-block;
                margin-left: auto;
                margin-right: 10px;

                &:hover {
                    color: darken($blue, 10%);
                }
            }

        }

        span.ssh-convert {
            display: inline-block;
            margin-top: 10px;
            font-size: 14px;
        }

        .second-factor {
            display: flex;

            .button-container {
                display: flex;
            }

            a {
                margin: auto 0 auto 20px;
            }
        }

        div.ip-networks {
            margin: 25px 0;

            label.title {
                display: flex;
                align-items: center;
                margin: 12px 0 6px 0;
                min-width: 210px;
                color: black;
                position: relative;
                font-weight: bold;
            }

            span.tool-tip-section {
                font-size: 14px;
                color: $lighter-grey;
                cursor: pointer;
                padding: 0 4px;

                &:hover {
                    color: darken($light-blue, 20%);
                }
            }

            span.add-network {
                color: $blue;
                cursor: pointer;
                padding: 0 4px;
                font-size: 22px;
                display: inline-block;
                margin-left: auto;
                margin-right: 10px;

                &:hover {
                    color: darken($blue, 10%);
                }
            }

            .network-container {
                margin: 0 0 15px 0;
            }

            .network {
                display: flex;
                width: 100%;
                align-items: center;

                .input-field {
                    width: 100%;
                }

                span.trash {
                    margin: 15px 0 0 16px;
                    max-height: 44px;
                    text-align: center;
                    border: 1px solid var(--sds--color--red--400);
                    border-radius: 4px;
                    padding: 8px 14px;
                    color: var(--sds--color--red--400);
                    cursor: pointer;
                    display: inline-block;
                }
            }

            span.network-info {
                font-style: italic;
                font-size: 14px;
                display: inline-block;
                margin-top: 2px;
            }
        }

        .ssh-keys-actions {
            display: flex;
            align-items: center;
            margin-bottom: 60px;

            a {
                margin-left: 25px;
                color: var(--sds--palette--support-color--500);
            }

            .update-action {
                margin-left: auto;
            }
        }
    }

    .ssh-keys-zero-state {
        margin: 20px 0;
    }

    .delete-profile {
        p {
            margin: 15px 0 25px 0;
        }
    }
}



