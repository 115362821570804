@import "../stylesheets/vars.scss";

div.email-field {
    display: flex;
    position: relative;
    flex-direction: column;

    label {
        display: inline-block;
        margin: 12px 0 6px 0;
        min-width: 210px;
        color: black;
        position: relative;
        font-weight: 600;
    }

    .inner-email-field {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border: 1px solid $dark-grey;
        border-radius: $br;
        font-size: 16px;

        &:focus-within {
            outline: none;
            box-shadow: 3px 3px 3px $blue-hover, -3px -3px 1px $blue-hover;
        }

        &.error {
            border: 1px solid $error-red;
        }

    }

    textarea {
        height: 70px;
        border: none;
        flex-grow: 2;
        display: inline-block;
        background-color: transparent;
        font-size: 16px;
        margin: 14px 5px 5px 5px;

        &:focus {
            border: none;
            outline: none;
        }
    }

    div.email-tag {
        border: 1px solid $lighter-grey;
        border-radius: $br;
        margin: 10px 5px 0 5px;
        display: inline-block;
        position: relative;
        background-color: $lightest-grey;
        padding: 4px 26px 4px 10px;
        max-height: 32px;

        span {
            &:first-child {
                word-break: break-all;
            }

            &.icon {
                padding: 8px 14px;
                cursor: pointer;
                font-size: 14px;
                color: $dark-grey;
                position: absolute;
                right: -8px;
                top: -2px;

                &:hover {
                    color: var(--sds--color--red--400);
                }

                &.disabled {
                    cursor: not-allowed;
                    color: $green;
                }
            }
        }
    }

    p.error {
        color: var(--sds--color--red--400);
        margin-top: var(--sds--space--1);
    }


}