@import "../stylesheets/vars.scss";

.mod-service-denied {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    background-color: $background;

    .content-container {
        background-color: white;
        @media (max-width: $tablet-max) {
            padding: 0 15px;
        }

    }

    div.actions {
        margin-top: 25px;
        display: flex;
        gap: 40px;
    }


    .content {
        max-width: $compact-medium;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;

        h1 {
            margin: 20px 0 15px 0;
        }

        svg {
            margin-top: 25px;
            width: 180px;
            height: auto;
        }

        div.status {
            margin: 15px 0;
        }


    }

    .info-container {
        @media (max-width: $compact-medium) {
            padding: 15px;
        }

    }

    .service-denied-info {
        display: flex;
        background-color: white;
        gap: 40px;
        max-width: $compact-medium;
        border-radius: 16px;
        padding: 25px 40px;
        margin: 30px auto 0;
        min-height: 244px;

        @media (max-width: $compact-medium) {
            flex-direction: column;
            width: 100%;
            padding: 15px 20px;
        }

        svg {
            width: 200px;
            height: auto;
            margin: 15px 0 auto 0;

            @media (max-width: $compact-medium) {
                width: 180px;
            }
        }

        ul {
            padding: 0 $medium-padding;
            margin: 5px 0;
            list-style: initial;

            li {
                line-height: 26px;
            }
        }
    }

    div.ticket {

        p {
            margin-bottom: 8px;
        }

        .session-info {
            display: flex;
            flex-direction: column;
        }

        span {
            font-weight: 600;

            &.value {
                font-weight: normal;
                margin-bottom: 10px;
            }
        }
    }

}
