@import "../stylesheets/vars.scss";

.mod-service-aup {
    max-width: $compact-medium;
    margin: 25px auto 40px auto;
    width: 100%;

    @media (max-width: 680px) {
        padding: 0 15px;
    }

    h1 {
        margin-bottom: 25px;
    }

    div.collaboration-detail {
        margin-bottom: 25px;
    }

    h2 {
        margin: 20px 0 15px 0;
    }

    p.multiple-collaborations {
        margin: 20px 0;
    }

    p.login {
        margin-bottom: 25px;
    }

    div.actions {
        margin: 25px 0;
    }

}