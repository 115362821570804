@import "../../stylesheets/vars";

span.error-indication {
    color: $error-red;

    display: flex;
    align-items: center;
    margin-top: 5px;

    &.standalone {
        margin-bottom: 15px;
    }

    span.error-message {
        word-break: keep-all;
    }

    span.sub-msg {
        margin-left: 12px;
    }

    svg {
        margin-right: 10px;
        width: 18px;
        height: auto;
    }
}