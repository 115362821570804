@import "../../stylesheets/vars";

p.last-admin-error-indication {
    color: $error-red;
    flex-direction: column;
    display: flex;
    align-items: center;

    svg {
        margin-bottom: 15px;
        width: 40px;
        height: auto;
    }
}