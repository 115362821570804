@import "../stylesheets/vars.scss";

div.organisation-tags {
    margin-top: 27px;

    width: 100%;
    display: grid;
    grid-template-columns: [first] 8fr [second] 8fr [third] 1fr;
    grid-auto-rows: min-content;
    column-gap: 12px;
    row-gap: 14px;
    align-items: center;

    div.units-inner__control, div.input-units-inner {
        min-height: 3rem;
    }

    input[type="text"], a.add-tag, span.error-indication {
        grid-column-start: first;
    }

    div.input-field-delete {
        border: 1px solid var(--sds--color--gray--400);
        font-size: 20px;
        color: var(--sds--color--gray--400);
        cursor: pointer;
        display: flex;
        border-radius: 4px;


        &:hover {
            background-color: var(--sds--color--gray--100);
        }


        a {
            padding: 12px 12px 0 12px;
        }

        svg {

            color: var(--sds--color--gray--400);
            cursor: pointer;

            &:hover {
                color: var(--sds--color--red--400);
            }
        }
    }

    a.add-tag {
        margin-top: 15px;
    }


}