.welcome {

    ul {
        list-style: initial;
        margin-left: 25px;
        line-height: 24px;
    }

    p {
        margin-bottom: 20px;

        &.no-margin {
            margin-bottom: 0;
        }
    }
}
