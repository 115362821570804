table.users {

    thead {
        th {
            &.name {
                width: 50%;
            }

            &.schac_home_organisation {
                width: 25%;
            }

            &.role {
                width: 25%;
            }
        }
    }

    tbody {
        td {
            &.member {
                text-align: left;
            }
        }
    }
}
