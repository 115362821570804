@import "../stylesheets/vars.scss";
@import "../stylesheets/circle.scss";


.mod-mfa {
    max-width: 570px;
    margin: 25px auto 40px auto;
    width: 100%;

    @media (max-width: 930px) {
        padding: 0 15px;
    }

    &.verify {
        max-width: 570px;
    }

    &.reset-request {
        max-width: 920px;
    }

    h1 {
        margin-bottom: 25px;
    }

    .register-header {
        padding: $medium-padding 0 10px 0;

        .sds--toaster {
            max-width: initial;
            margin: 20px 0;
        }
    }

    .authenticator-problems {
        padding: $medium-padding 0;
        display: flex;
        gap: 60px;

        @media (max-width: 900px) {
            flex-direction: column;
        }


        .left {
            display: flex;
            flex-direction: column;
            max-width: 48%;
            padding: 25px 25px 0 25px;
            border-radius: 0.375rem;
            box-shadow: 0 0.25rem 0.25rem 0 var(--sds--color--pitch-black--dimmed--25);

            @media (max-width: 930px) {
                max-width: 100%;
            }

            svg {
                width: 240px;
                height: auto;
                margin: 0 auto 20px auto;

            }

            ul {
                margin-top: 20px;
                margin-left: 1px;
            }
        }

        .right {
            p.unit {
                font-weight: 600;
                margin-top: 25px;
            }
        }

        ul {
            margin-bottom: 25px;
        }

        .select-respondents {
            .checkbox {
                padding-top: 12px;

                label.disabled {
                    color: black;
                }

                .checkbox--text {
                    font-weight: initial;
                }
            }
        }
    }

    .input-field-container {
        input[type='text'] {
            max-width: 125px;
        }

    }

    .input-field-container-large {
        min-width: 100%;

        input[type='text'] {
            min-width: 100%;
        }

    }

    .step {
        margin-bottom: 30px;

        &.clear {
            margin-bottom: 0;
        }
    }

    .step-actions {
        padding-top: 5px;

        h1 {
            margin-bottom: 15px;
        }

        &.center {
            display: flex;

            .input-field-container {
                flex-direction: column;
                display: flex;
                margin: 0 auto 25px auto;
            }

        }
    }


    ul {
        padding: 0 $medium-padding;
        margin-left: 15px;
        list-style: initial;

        li {
            line-height: 26px;
        }
    }

    .qr-code-container img {
        width: 225px;
        height: auto;
        margin-left: 50px;
        margin-top: 20px;
    }

    .shared-secret {
        display: flex;
        width: 225px;
        margin-left: 50px;

        &.with-secret {
            margin-left: 25px;
        }

        a, p {
            margin: 0 auto;
        }
    }

    .explain {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        font-size: 15px;
        margin-top: 15px;

        a {
            display: block;
            margin-top: 3px;
        }
    }

    span.error {
        display: block;
        margin-top: 5px;
        color: var(--sds--color--red--400);
    }

    section.actions {
        display: flex;
        margin-top: 20px;

        button {
            margin-top: 0;
        }

    }

    div.totp-value-container {
        display: flex;
        margin-top: 15px;
        width: 330px;
        justify-content: space-between;

        input.totp-value {
            width: 40px;
            padding-left: 14px;
            height: 40px;
            font-size: 22px;
            border: 1px solid rgb(118, 118, 118);

            &[disabled] {
                background-color: rgba(239, 239, 239, 0.3);
                border: 1px solid rgba(118, 118, 118, 0.3);
            }
        }
    }
}
