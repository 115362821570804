@import "../../stylesheets/vars";
@import "../../stylesheets/select_role";

.mod-entities {

    table.serviceAdmins {

        thead {
            th {
                &.check {
                    width: 15px;
                }

                &.name {
                    width: 35%;
                }

                &.user__schac_home_organisation {
                    width: 20%;
                }

                &.role {
                    width: 15%;
                }

                &.status {
                    width: 20%;
                    text-align: center;
                }

                &.impersonate {
                    width: 100px;
                }
            }
        }

        tbody {
            td {
                &.status {
                    text-align: center;
                }

                &.not-allowed {
                    cursor: not-allowed;
                }

                &.impersonate {
                    text-align: right;
                    padding: 0;
                }

            }
        }

    }

}
