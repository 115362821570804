@import "../../stylesheets/vars";
@import "../../stylesheets/forms";
@import "../../stylesheets/unit_header";

table.groups {

    thead {
        th {
            &.check {
                width: 15px;
            }

            &.name {
                width: 20%;
                padding-left: 15px;
            }

            &.description {
                width: 30%;
            }

            &.member {
                width: 120px;
            }

            &.memberCount {
                width: 10%;
                text-align: center;
            }

            &.service_group__service__name {
                width: 22%;
            }

            &.auto_provision_members {
                width: 14%;
                text-align: center;
            }

            &.trash {
                width: 15px;
            }
        }
    }

    tbody {
        td {
            &.name {
                padding-left: 15px;
            }

            &.memberCount {
                text-align: center;
            }

            &.auto_provision_members {
                text-align: center;
            }

            &.trash {
                text-align: right;
                padding-left: 0;
            }
        }
    }
}

.loader-container {
    margin-top: 40px;

    .sds--loading-container {
        .sds--loading {
            position: unset;
        }
    }
}

.group-details-container {
    display: flex;
    max-width: $medium;
    width: 100%;
    margin: 40px auto 25px auto;

    @media (max-width: $medium) {
        padding: 0 15px;
    }

    @media (max-width: $compact-medium) {
        flex-direction: column;
    }

    a.back-to-groups {
        display: flex;
        align-items: center;
        white-space: nowrap;
        color: $blue;
        text-decoration: underline;
        margin-right: 60px;

        svg {
            margin-right: 5px;
            fill: $blue;
            height: 22px;
        }
    }

    div.group-details {
        width: 100%;

        .group-details-header {
            background-color: $background;
            padding: 15px 25px;
        }

        .group-meta-data {
            display: flex;
            gap: 80px;

            @media (max-width: $compact-medium) {
                flex-direction: column;
                gap: 20px;
            }

            div.meta-data-item {
                display: flex;
                flex-direction: column;
                gap: 1px;

                span.item-label {
                    font-weight: 600;
                }
            }
        }

        .group-urn {
            padding: 0 2px 0 4px;
            background-color: var(--sds--color--blue--100);
            border: 1px solid var(--sds--color--blue--400);
            border-radius: 4px;
        }

        section.copy-to-clipboard {
            border: none;
            padding: 0 4px 0 0;
            display: flex;

            svg {
                width: 24px;
                height: auto;
            }
        }

        span.copy-link {
            padding-right: 8px;
            margin-right: 6px;
            border-right: 1px solid var(--sds--color--blue--400);
            display: inline-block;
            color: var(--sds--color--blue--500);
            cursor: pointer;

            &.copied {
                animation: twigger .4s;
                animation-iteration-count: 1;

                @keyframes twigger {
                    0% {
                        color: var(--sds--color--blue--400);
                    }
                    50% {
                        color: var(--sds--color--blue--300);
                    }
                    100% {
                        color: var(--sds--color--blue--500);
                    }
                }
            }
        }

        a.history {
            margin: 0 20px 0 auto;
            color: black;
            text-decoration: underline;
            font-weight: 600;
            cursor: pointer;

            @media (max-width: $compact-medium) {
                margin: 0;
            }

        }

        .header {
            display: flex;
            width: 100%;

            div.header-actions {
                margin-left: auto;
                display: flex;
                gap: 25px;
                align-items: center;
            }

            span.history {
                cursor: pointer;

                svg {
                    margin-right: 10px;
                }
            }
        }

        .description {
            margin-bottom: 25px;

            &.no-header-actions {
                margin-top: 25px;
            }
        }

        .group-detail-actions {
            max-width: 50%;
            margin-bottom: 20px;

            div.actions__placeholder {
                color: $blue;
            }
        }
    }

    .mod-entities table.groupMembers tbody td.icon div.member-icon {
        padding-left: 25px;
    }

    table.groupMembers {
        thead {
            th {
                &.check {
                    width: 15px;
                }

                &.icon {
                    width: 30px;
                }

                &.name {
                    width: 50%;
                }

                &.user__schac_home_organisation {
                    width: 35%;
                }

                &.trash, &.trash_disabled {
                    width: 74px;
                }

            }
        }

        tbody {
            td {
                &.trash {
                    text-align: right;
                    color: var(--sds--color--red--400);
                    cursor: pointer;
                }

                &.trash_disabled {
                    text-align: right;
                    color: $lighter-grey;
                    cursor: not-allowed;
                }

            }
        }

    }

    div.group-form {

        width: 60%;

        display: flex;
        flex-direction: column;

        @media (max-width: $compact-medium) {
            width: 100%;
            padding: 15px 15px 0 15px;
        }

        h1 {
            margin-bottom: 20px;
        }


        div.checkbox {
            margin-top: 25px;
        }
    }

}
