@import "../../stylesheets/vars";

.mod-service-collaborations {
    .info-container {
        background-color: transparent;

        .info {
            max-width: 1280px;
            margin: 24px auto 0 auto;
            padding: 8px 0;

            @media (max-width: $medium) {
                padding: 8px 15px;

            }

            button {
                margin-left: 60px;
                @media (max-width: $compact-medium) {
                    margin-top: 15px;
                    margin-left: 0;
                    display: block;
                }
            }
        }

    }
}

table.serviceCollaborations {

    thead {
        th {
            @media (max-width: $compact-medium) {
                &.role {
                    display: none;
                }
            }

            &.check {
                width: 50px;
            }

            &.name {
                width: 20%;
                padding-left: 15px;
            }

            &.organisation__name {
                width: 25%;
            }

            &.short_name {
                width: 25%;
            }

            &.derived_short_name {
                width: 25%;
            }

            &.role {
                width: 20%;
                text-align: center;
            }

            &.action-icons {
                width: 70px;
            }

        }
    }

    tbody {
        td {
            @media (max-width: $compact-medium) {
                &.role {
                    display: none;
                }
            }

            &.check {
                svg.information-circle {
                    width: 20px;
                    height: 20px;
                }
            }

            &.name {
                padding-left: 15px;
            }

            &.role {
                width: 15%;
                text-align: center;
            }

        }
    }

}
