.user-menu {
    margin-left: auto;
    z-index: 99;

    color: black;

    button.sds--user-info--button {
        background-color: white;

        svg {
            color: var(--sds--palette--main-color--400);
        }
    }

    .sds--user-info--textual p.name, .sds--user-info--textual p.role {
        color: white;
    }
}

