@import "../stylesheets/vars.scss";

.mod-delay {
    max-width: $compact-medium;
    margin: 25px auto 40px auto;
    width: 100%;

    @media (max-width: 680px) {
        padding: 0 15px;
    }

    h1 {
        margin: 25px 0;
    }

    img {
        width: 370px;
        display: block;
        margin: 0 auto;
    }

}