@import "../stylesheets/vars.scss";

.app-container {
    display: flex;
    position: relative;
    min-height: 100vh;
    width: 100%;
    flex-direction: column;

    .select-disabled__single-value {
        color: var(--sds--color--gray--500);
    }

}

