@import "../stylesheets/vars.scss";

.tabs-container {
    width: 100%;
    background-color: $background;

    .tabs {
        max-width: $medium;
        margin: 0 auto;
        display: flex;
        gap: var(--sds--space--1);

        @media (max-width: $tablet-max) {
            padding: 0 15px;
        }

        @media (max-width: $compact-medium) {
            flex-direction: column;
            padding: 0;
        }

    }

}
