@import "../stylesheets/vars.scss";

div.mod-aup-services {
    div.service-section {
        display: flex;
        padding: 7px 10px;
        border-bottom: 1px solid $lighter-grey;
        align-items: center;
        width: 100%;
        min-height: 56px;
        margin-bottom: 15px;

        &:first-child {
            border-top: 3px solid $lighter-grey;
        }

        img {
            border-radius: 4px;
            max-width: 76px;
        }

        table {
            width: 100%;

            td.logo {
                width: 85px;
            }

            td.service-detail {
                width: 90%;

                div.service {
                    display: flex;
                    flex-direction: column;
                    margin-left: 15px;

                    span.service-name {
                        margin-bottom: 6px;
                    }
                }

                div.policies {
                    display: flex;
                    gap: 25px;
                    width: 100%;

                    .policy {
                        flex: 1;

                        &:not(:last-child) {
                            padding-right: 25px;
                            border-right: 1px solid $dark-grey;

                        }
                    }
                }

                div.contact {
                    display: flex;
                    align-content: center;

                    svg {
                        margin-right: 10px;
                    }
                }
            }

        }

        span.no-link {
            color: $darker-grey;
            font-style: italic;
            max-width: 220px;
            display: inline-block;
        }
    }

    div.terms {
        margin: 20px 0 0 0;

        p.aup-info {
            margin-bottom: 15px;
        }
    }

    h4.aup-services {
        margin: 5px 0;
    }


}