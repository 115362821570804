@import "../../stylesheets/vars";

div.service-about-mod {
    padding: 32px 0;
    max-width: $medium;
    width: 100%;
    margin: 0 auto;
    display: flex;
    gap: 60px;

     @media (max-width: $tablet-max) {
        flex-direction: column;
         padding: 32px 15px;
    }

    span.role {
        color: $dark-grey;
        font-size: 14px;
    }
}
