@import "../stylesheets/vars.scss";

.mod-pam-container {
    .form {
        display: flex;
        flex-direction: column;
        max-width: 50%;
    }

    button.sds--btn {
        margin-top: 40px;
        max-width: 160px;
    }

    .pam-weblogin-results {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 2px solid var(--sds--color--gray--200);
        display: flex;
        flex-direction: column;

        img {
            width: 160px;
            height: auto;
        }

        h4 {
            margin: 20px 0;
        }

        h6 {
            margin-top: 20px;
        }

        ul {

            list-style: circle;

            li {
                margin-left: 25px;
            }
        }
    }
}