div.select-member-role__control {
    border: none;
    cursor: pointer;
    max-width: 140px;

    &:hover {
        background-color: $light-blue;
    }

    .select-member-role__value-container {
        border: none;
        padding-left: 5px;
    }

    .select-member-role__single-value {
        color: $blue;
        font-weight: bold;
    }

    .select-member-role__indicator-separator {
        display: none;
    }

    .select-member-role__indicator svg {
        fill: $blue
    }
}

div.select-member-role--is-disabled {

    div.select-member-role__control--is-disabled {

        div.select-member-role__value-container {

            div.select-member-role__single-value {
                cursor: not-allowed;
                color: $dark-grey;
            }
        }

        div.select-member-role__indicators {
            display: none;
        }
    }

}
