// Colors
$blue: #006db8;
$medium-blue: var(--sds--palette--support-color--400);
$lighter-blue: #a8d9e6;
$light-blue: #dff4ff;
$information-blue: #99dcea;
$hover-blue: #004c97;

$cancel: #bdccd5;

$darker-blue: #4b4b4b;
$red: var(--sds--color--red--400);
$error-red: var(--sds--color--red--400);
$warning-red: #ffc5c1;
$error-red-background: #fff5f3;
$light-green: #b5f2c5;
$primary-green: #01794f;
$green: #2CA055;
$styleboard-green: #009f4d;
$dark-green: #276b1e;
$primary-orange: #c95102;
$light-orange: #ffa640;
$yellow: #fef595;

$old_background: #f4f6f8;
$background: #eaebf0;
$light-grey: #eff2f3;
$lighter-grey: #e0e0df;
$lightest-grey: #fbfbfb;
$hover: #F9FCFD;
$medium-grey: #c3ccd4;
$dark-grey: #737373;
$darker-grey: #3b3b3b;

$blue-light: #9adbe8;
$blue-hover: #ddf2fd;
$yellow-light: #ffeca6;
$green-light: #53d07a;
$purple: #772583;

// borders
$border: rgba(black, .1);
$border-light: rgba(white, .2);

// Sizes
$br: 6px;

// Screen
$medium: 1280px;
$compact-medium: 968px;
$medium-padding: 25px;
$medium-margin: 30px;

//Tablet
$tablet-max: 1300px;