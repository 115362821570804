@import "../../stylesheets/vars";

.mod-entities {

    max-width: $medium;
    width: 100%;
    margin: 0 auto;

    @media (max-width: $tablet-max) {
        padding: 0 15px;
    }


    &:not(.first) {
        margin-bottom: 25px;
    }

    .entities-search {
        display: flex;
        align-items: center;
        padding: 32px 0 28px 0;
        min-height: 40px;
        justify-content: flex-end;

        .search-filters {
            margin: 0 20px 0 auto;
        }

        @media (max-width: $compact-medium) {
            flex-direction: column;
            align-items: normal;
        }

        h2 {
            @media (max-width: $compact-medium) {
                margin-bottom: 15px
            }

        }

        div.filter-select__value-container {
            min-height: 46px;
        }

        div.filter-select__control {
            min-height: 46px;
            border-color: var(--sds--color--gray--400);
        }

        svg.help {
            color: $blue;
            font-size: 14px;
            cursor: pointer;
            margin: 0 8px 18px auto;

            &:hover {
                color: darken($blue, 10%);
            }
        }

        .search {
            position: relative;
            display: flex;

            @media (max-width: $compact-medium) {
                margin-left: 0;
            }

            input[type=search] {
                flex-grow: 2;
                min-width: 260px;

                @media (max-width: $compact-medium) {
                    min-width: 0;
                }
            }

            .sds--text-field--icon svg {
                width: calc(15em / 16);
                height: calc(15em / 16)
            }

        }

    }

    div.new-button {
        margin-left: 20px;
        button {
           min-width: 180px;
        }
    }

    section.entities-list {
        position: relative;

        div.actions-header {
            position: absolute;
            left: 76px;
            top: 2px;

            .admin-actions {
                display: flex;
                min-height: 32px;
                margin-bottom: 12px;

                div:not(:first-child) {
                    margin-left: 25px;
                }

                button.right {
                    margin-left: auto;
                }

            }

            &.collaboration-services {
                left: 32px;
            }

            &.groups {
                left: 48px;
            }

            &.collaboration-groups {
                width: 100%;
                left: 0;
                top: -2px;
                position: relative;
            }

            &.adjust-top {
                top: 65px;
            }
        }

        .sds--pagination {
            margin-top: 25px;
        }
    }

    svg.impersonate {
        width: 40px;
        height: auto;
        cursor: pointer;
        fill: #737373;
        padding: 5px;
    }


    table {
        width: 100%;
        table-layout: fixed;

        thead {
            th {

                &.sortable {
                    cursor: pointer;
                }

                &.icon {
                    width: 64px;
                    padding-right: 0;
                    padding-left: 0;
                }

                &.logo {
                    width: 85px;
                }

                svg.fa-caret-up, svg.fa-caret-down {
                    margin-left: 5px;
                }

                &.checker {
                    width: 35px;
                }

                &.center {
                    text-align: center;
                }

                &.right {
                    text-align: right;
                }

                &.hide {
                    color: white;
                    cursor: default;
                }

                @media (max-width: $compact-medium) {
                    &.status, &.requester, &.impersonate, &.logo, &.collaboration_memberships_count,
                    &.invitations_count, &.organisation_memberships_count, &.collaborations_count,
                    &.connection_requests_count, &.icon, &.user__schac_home_organisation {
                        display: none;
                    }
                }

            }


        }

        tbody {
            tr {
                &.clickable {
                    cursor: pointer;
                }

                .action-icons-container {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }

                .admin-icons {
                    display: none;
                    margin: auto 0;

                    svg {
                        color: $dark-grey;
                        cursor: pointer;
                        margin: 0 5px;
                        width: 20px;
                        height: auto;

                        &:hover {
                            color: black;
                        }
                    }
                }

                &.hoverable {
                    &:hover {
                        div.admin-icons {
                            display: flex;
                        }
                    }
                }
            }

            td {
                word-break: break-word;
                vertical-align: middle;

                &.center {
                    text-align: center;
                }

                &.right {
                    text-align: right;
                }

                &.logo {
                    text-align: center;

                    svg.image-not-found {
                        width: 48px;

                    }
                }

                &.icon {
                    text-align: center;
                    padding-left: 0;
                    padding-right: 0;

                    div.member-icon {
                        display: flex;
                        justify-content: space-around;

                        svg {
                            width: 24px;
                        }
                    }
                }

                &.logo {
                    padding: 8px 0 4px 0;

                    img {
                        border-radius: 4px;
                        width: 80px;
                        height: auto;
                        margin-right: 5px;
                    }
                }

                @media (max-width: $compact-medium) {
                    &.status, &.requester, &.impersonate, &.logo, &.collaboration_memberships_count,
                    &.invitations_count, &.organisation_memberships_count, &.connection_requests_count,
                    &.collaborations_count, &.icon, &.user__schac_home_organisation {
                        display: none;
                    }
                }

                .sds--chips span {
                    white-space: nowrap;
                }
            }
        }
    }

    div.user-name-email {
        display: flex;
        flex-direction: column;

        span.email {
            color: $dark-grey;
        }
    }

    div.chip-container {

        .sds--chips {
            margin: 0 auto;
        }
    }

    .sds--pagination a {
        font-weight: normal;
    }

}


