@import "../../stylesheets/vars";
@import "../../stylesheets/select_role";

.mod-entities {

    thead {
        th {
            &.check {
                width: 15px;
            }

            &.name {
                width: 25%;
            }

            &.units {
                width: 17%;
            }

            &.user__schac_home_organisation {
                width: 15%;
            }

            &.role {
                width: 15%;
            }

            &.status {
                width: 18%;
                text-align: center;
            }

            &.impersonate {
                width: 100px;
            }
        }
    }

    tbody {
        td {
            &.check svg.information-circle {
                width: 20px;
                height: 20px;
            }

            &.units {
                .unit-container {
                    display: flex;
                    flex-direction: column;
                }
            }

            &.status {
                text-align: center;
            }

            &.not-allowed {
                cursor: not-allowed;
            }

            &.impersonate {
                text-align: right;
                padding: 0;
            }

        }
    }

    .tooltip-table-cell {
        position: relative;

        .sds--tooltip-container {
            position: absolute;
            top: -18px;
            left: 5px;
        }
    }
}

.member-details-container {
    display: flex;
    max-width: $medium;
    width: 100%;
    margin: 40px auto 25px auto;

    @media (max-width: $compact-medium) {
        flex-direction: column;
        padding: 0 15px;
    }

    a.back-to-groups {
        display: flex;
        align-items: center;
        white-space: nowrap;
        color: $blue;
        text-decoration: underline;
        margin-right: 60px;

        svg {
            margin-right: 5px;
            fill: $blue;
            height: 22px;
        }
    }

    .user-detail {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 15px;
        margin-top: 20px;
        background-color: $background;
    }
}

