@import "../stylesheets/vars.scss";

.sds--modal--container.welcome-dialog {
    width: 75vw;
    max-width: 665px;
    max-height: 75vh;
    overflow: initial;

    &.collaboration {
        margin-top: 40px;
    }
}

.welcome-dialog-content {
    display: flex;
    flex-direction: column;

    div.toggle-role {
        margin-left: auto;
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        label.switch {
            margin-left: auto;
        }

        span.toggle-txt {
            margin-left: 15px;
            font-size: 18px;
        }
    }

    section.role {
        display: flex;
        align-items: center;
        border-radius: 4px;
        padding: 15px;
        margin-bottom: 35px;

        svg {
            margin-right: 15px;
        }

        background-color: #95d7e4;
    }
}
