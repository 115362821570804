@import "../stylesheets/vars.scss";

.mod-pam-web-sso-container {
    display: flex;
    width: 100%;

    .mod-pam-web-sso {
        max-width: 570px;
        margin: 25px auto 40px auto;
        display: flex;
        flex-direction: column;

        @media (max-width: 680px) {
            padding: 0 15px;
        }

        h1 {
            margin-bottom: 25px;
        }

        h3 {
            font-size: 22px;
            line-height: 26px;
        }

        .success {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        span.attr {
            font-weight: bold;
        }

        .service {
            display: flex;
            margin-bottom: 25px;

            .image img {
                max-height: 300px;
                max-width: 300px;
                border-radius: 5px;
            }

            .details {
                display: flex;
                flex-direction: column;
                margin-left: 15px;
            }
        }

        p.info {
            margin: 5px 0 15px 0;
        }

        div.org-attribute {
            margin-bottom: 4px;
        }

        p.pin {
            font-weight: bold
        }

        div.pin-value {
            display: flex;
            margin: 20px 0 35px 0;
            padding: 20px;
            background: $background;
            width: 100%;

            div.pin-value-inner {
                display: flex;
                gap: 60px;
                margin: 0 auto;

                span.value {
                    font-size: 40px;
                    font-weight: 600;
                    letter-spacing: 16px;
                    line-height: 36px;
                    margin-top: 6px;
                }

                button.sds--btn--ghost--dark {
                    color: var(--sds--color--gray--500);
                    font-size: 20px;
                    background-color: white;

                    svg {
                        height: auto;
                        width: 22px;
                        color: var(--sds--color--gray--500);
                    }

                    &:hover {
                        background-color: black;
                        color: white;

                        svg {
                            color: white;
                        }

                    }

                    &:active {
                        color: black;
                        font-size: 20px;
                        background-color: white;

                        svg {
                            color: black;

                        }
                    }

                }
            }
        }

        div.actions {
            margin: 25px 0;
        }

    }
}