@import "../stylesheets/vars.scss";

.header-container {

    background-color: var(--sds--palette--main-color--400);
    color: var(--sds--color--white);
    margin: 0 auto;
    padding: 5px 0 10px 0;

    .header-inner {
        position: relative;
        transition: margin 150ms ease-in-out;
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0 auto;
        max-width: $medium;

        @media (max-width: $tablet-max) {
            padding: 0 15px;
        }

        a.logo {
            margin-top: 10px;
            text-decoration: none;
        }

    }

}





