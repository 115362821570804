section.actions {
    margin-top: 30px;
    padding: $medium-margin 0;
    border-top: 2px solid $lighter-grey;
    display: flex;
    justify-content: flex-end;
    position: relative;

    button:not(:first-child) {
        margin-left: 25px;
    }

    button.sds--btn--delete, button.sds--btn--delete--secondary  {
        margin-right: auto;
        margin-left: 0;
    }

    span.error {
        position: absolute;
        top: 72px
    }


}

div.input-field, div.date-field, div.select-field {
    margin-top: 15px;
}

svg.informational {
    color: white;
}

.cut-of-lines {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
}

sup.required {
    color: var(--sds--color--red--500);
}
